import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import PrivacyPolicyCompany from "../../Pages/PrivacyPolicy/PrivacyPolicyCompany";
// import { Link } from "react-router-dom";

const CompanyModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    companyName: "",
    companyMobileNumber: "",
    companyNature: "",
    address: {
      governorate: "",
      street: "",
      area: "",
      buildingNumber: "",
      near: "",
      floor: "",
      officeNumber: "",
    },
    jobPosition: [
      {
        gender: "",
        requiredNumber: "",
        workingHours: "",
        fromTime: "",
        toTime: "",
        residenceNear: "",
      },
    ],
    ageRange: { from: "", to: "" },
    nationality: "",
    education: {
      highSchool: false,
      diploma: false,
      bachelor: false,
      master: false,
      notRequired: false,
    },
    fieldWork: "",
    preferredConditions: "",
    commission: {
      exists: false,
      percentage: "",
    },
    incentives: false,
    overtime: false,
    securedTransport: false,
    healthInsurance: false,
    socialSecurity: false,
  });

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({ ...prevData, [name]: checked }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleEducationChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      education: {
        ...prevData.education,
        [name]: checked,
      },
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address,
        [name]: value,
      },
    }));
  };

  const handleJobPositionChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    const updatedPositions = formData.jobPosition.map((pos, i) =>
      i === index
        ? { ...pos, [name]: type === "checkbox" ? checked : value }
        : pos
    );
    setFormData((prevData) => ({
      ...prevData,
      jobPosition: updatedPositions,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/companies`,
        { ...formData }
      );

      if (response.data?.success) {
        toast.success("تم ارسال طلبك بنجاح");
        onClose();
      }
    } catch (error) {
      toast.error("حدث خطأ ما، يرجى المحاولة مرة أخرى");
    }
  };
  return (
    <div
      className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 overflow-auto"
      tabIndex="-1"
      aria-hidden={!isOpen}
    >
      <div className="relative p-4 w-full max-w-2xl max-h-full">
        <div className="relative bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900">
              فورم الشركات والمؤسسات
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={onClose}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-6">
            <PrivacyPolicyCompany />
            <form
              onSubmit={handleSubmit}
              className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg"
            >
              <h2 className="text-2xl font-bold mb-4">وظيفة طلب نموذج</h2>

              <div className="mb-4">
                <label className="block text-gray-700">
                  اسم الشركة او المؤسسة
                </label>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">
                  رقم هاتف الشركة او المؤسسة
                </label>
                <input
                  type="text"
                  name="companyMobileNumber"
                  value={formData.companyMobileNumber}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">طبيعة عمل الشركة</label>
                <input
                  type="text"
                  name="companyNature"
                  value={formData.companyNature}
                  onChange={handleChange}
                  className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>

              <div className="mb-4">
                <h3 className="text-lg font-semibold">العنوان</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-gray-700">المحافظة</label>
                    <input
                      type="text"
                      name="governorate"
                      value={formData.address.governorate}
                      onChange={handleAddressChange}
                      className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">الشارع</label>
                    <input
                      type="text"
                      name="street"
                      value={formData.address.street}
                      onChange={handleAddressChange}
                      className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">المنطقة</label>
                    <input
                      type="text"
                      name="area"
                      value={formData.address.area}
                      onChange={handleAddressChange}
                      className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">رقم المبنى</label>
                    <input
                      type="text"
                      name="buildingNumber"
                      value={formData.address.buildingNumber}
                      onChange={handleAddressChange}
                      className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">بالقرب من</label>
                    <input
                      type="text"
                      name="near"
                      value={formData.address.near}
                      onChange={handleAddressChange}
                      className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">الطابق</label>
                    <input
                      type="text"
                      name="floor"
                      value={formData.address.floor}
                      onChange={handleAddressChange}
                      className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">مكتب رقم</label>
                    <input
                      type="text"
                      name="officeNumber"
                      value={formData.address.officeNumber}
                      onChange={handleAddressChange}
                      className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    />
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <h3 className="text-lg font-semibold">الوظيفة المطلوبة</h3>
                {formData.jobPosition.map((position, index) => (
                  <div
                    key={index}
                    className="border p-4 mb-4 rounded-md shadow-sm"
                  >
                    <div className="mb-2">
                      <label className="block text-gray-700">وظيفة</label>
                      <input
                        type="text"
                        name="position"
                        value={position.position}
                        onChange={(e) => handleJobPositionChange(index, e)}
                        className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        required
                      />
                    </div>

                    <div className="mb-2">
                      <label className="block text-gray-700">ذكور، إناث</label>
                      <input
                        type="text"
                        name="gender"
                        onChange={(e) => handleJobPositionChange(index, e)}
                        value={position?.gender}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      />
                    </div>

                    <div className="mb-2">
                      <label className="block text-gray-700">
                        العدد المطلوب
                      </label>
                      <input
                        type="number"
                        name="requiredNumber"
                        value={position?.requiredNumber}
                        onChange={(e) => handleJobPositionChange(index, e)}
                        className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        required
                      />
                    </div>

                    <div className="mb-2">
                      <label className="block text-gray-700">
                        عدد ساعات العمل المطلوبة
                      </label>
                      <input
                        type="number"
                        name="workingHours"
                        value={position.workingHours}
                        onChange={(e) => handleJobPositionChange(index, e)}
                        className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        required
                      />
                    </div>

                    <div className="mb-2">
                      <label className="block text-gray-700">من الساعة</label>
                      <input
                        type="time"
                        name="fromTime"
                        value={position.fromTime}
                        onChange={(e) => handleJobPositionChange(index, e)}
                        className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        required
                      />
                      <label className="block text-gray-700">إلى الساعة</label>
                      <input
                        type="time"
                        name="toTime"
                        value={position.toTime}
                        onChange={(e) => handleJobPositionChange(index, e)}
                        className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        required
                      />
                    </div>

                    <div className="mb-2">
                      <label className="block text-gray-700">
                        مكان إقامة الموظف قريب من
                      </label>
                      <input
                        type="text"
                        name="residenceNear"
                        value={position.residenceNear}
                        onChange={(e) => handleJobPositionChange(index, e)}
                        className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      />
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() =>
                    setFormData((prevData) => ({
                      ...prevData,
                      jobPosition: [
                        ...prevData.jobPosition,
                        {
                          gender: "",
                          requiredNumber: "",
                          workingHours: "",
                          fromTime: "",
                          toTime: "",
                          residenceNear: "",
                        },
                      ],
                    }))
                  }
                  className="text-white bg-blue-500 hover:bg-blue-700 px-4 py-2 rounded-md"
                >
                  إضافة وظيفة
                </button>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">العمر المطلوب من</label>
                <input
                  type="number"
                  name="ageRangeFrom"
                  value={formData.ageRange.from}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      ageRange: { ...prevData.ageRange, from: e.target.value },
                    }))
                  }
                  required
                  className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
                <label className="block text-gray-700">إلى</label>
                <input
                  type="number"
                  name="ageRangeTo"
                  value={formData.ageRange.to}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      ageRange: { ...prevData.ageRange, to: e.target.value },
                    }))
                  }
                  required
                  className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">الجنسية المطلوبة</label>
                <input
                  type="text"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                  className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">المؤهل العلمي</label>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    name="highSchool"
                    checked={formData.education.highSchool}
                    onChange={handleEducationChange}
                  />
                  <label className="ml-2">ثانوية عامة</label>
                  <input
                    type="checkbox"
                    name="diploma"
                    checked={formData.education.diploma}
                    onChange={handleEducationChange}
                    className="ml-4"
                  />
                  <label className="ml-2">دبلوم</label>
                  <input
                    type="checkbox"
                    name="bachelor"
                    checked={formData.education.bachelor}
                    onChange={handleEducationChange}
                    className="ml-4"
                  />
                  <label className="ml-2">باكالوريس</label>
                  <input
                    type="checkbox"
                    name="master"
                    checked={formData.education.master}
                    onChange={handleEducationChange}
                    className="ml-4"
                  />
                  <label className="ml-2">ماجستير</label>
                  <input
                    type="checkbox"
                    name="notRequired"
                    checked={formData.education.notRequired}
                    onChange={handleEducationChange}
                    className="ml-4"
                  />
                  <label className="ml-2">لايشترط</label>
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">
                  العمل الميداني أو بالموقع
                </label>
                <input
                  type="input"
                  name="fieldWork"
                  value={formData.fieldWork}
                  onChange={handleChange}
                  className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">
                  شروط أخرى يفضل توفرها بالموظف
                </label>
                <input
                  type="text"
                  name="preferredConditions"
                  value={formData.preferredConditions}
                  onChange={handleChange}
                  className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">
                  هل يوجد نسبة أو عمولة
                </label>
                <input
                  type="checkbox"
                  name="commissionExists"
                  checked={formData.commission.exists}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      commission: {
                        ...prevData.commission,
                        exists: e.target.checked,
                      },
                    }))
                  }
                  className="mr-2"
                />
                <label>نعم</label>
                <input
                  type="checkbox"
                  name="commissionExists"
                  checked={!formData.commission.exists}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      commission: {
                        ...prevData.commission,
                        exists: !e.target.checked,
                      },
                    }))
                  }
                  className="ml-4 mr-2"
                />
                <label>لا يوجد</label>
                {formData.commission.exists && (
                  <div className="mt-2">
                    <label className="block text-gray-700">
                      يرجى ذكر النسبة إن وجدت
                    </label>
                    <input
                      type="text"
                      name="commissionPercentage"
                      value={formData.commission.percentage}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          commission: {
                            ...prevData.commission,
                            percentage: e.target.value,
                          },
                        }))
                      }
                      required
                      className="mt-1 block w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    />
                  </div>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">حوافز</label>
                <input
                  type="checkbox"
                  name="incentives"
                  checked={formData.incentives}
                  onChange={handleChange}
                  className="mr-2"
                />
                <label>يوجد</label>
                <input
                  type="checkbox"
                  name="incentives"
                  checked={!formData.incentives}
                  onChange={handleChange}
                  className="ml-4 mr-2"
                />
                <label>لا يوجد</label>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">ساعات عمل إضافية</label>
                <input
                  type="checkbox"
                  name="overtime"
                  checked={formData.overtime}
                  onChange={handleChange}
                  className="mr-2"
                />
                <label>يوجد</label>
                <input
                  type="checkbox"
                  name="overtime"
                  checked={!formData.overtime}
                  onChange={handleChange}
                  className="ml-4 mr-2"
                />
                <label>لا يوجد</label>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">مواصلات مؤمنة</label>
                <input
                  type="checkbox"
                  name="securedTransport"
                  checked={formData.securedTransport}
                  onChange={handleChange}
                  className="mr-2"
                />
                <label>يوجد</label>
                <input
                  type="checkbox"
                  name="securedTransport"
                  checked={!formData.securedTransport}
                  onChange={handleChange}
                  className="ml-4 mr-2"
                />
                <label>لا يوجد</label>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">تأمين صحي</label>
                <input
                  type="checkbox"
                  name="healthInsurance"
                  checked={formData.healthInsurance}
                  onChange={handleChange}
                  className="mr-2"
                />
                <label>يوجد</label>
                <input
                  type="checkbox"
                  name="healthInsurance"
                  checked={!formData.healthInsurance}
                  onChange={handleChange}
                  className="ml-4 mr-2"
                />
                <label>لا يوجد</label>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">ضمان اجتماعي</label>
                <input
                  type="checkbox"
                  name="socialSecurity"
                  checked={formData.socialSecurity}
                  onChange={handleChange}
                  className="mr-2"
                />
                <label>يوجد</label>
                <input
                  type="checkbox"
                  name="socialSecurity"
                  checked={!formData.socialSecurity}
                  onChange={handleChange}
                  className="ml-4 mr-2"
                />
                <label>لا يوجد</label>
              </div>

              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
              >
                إرسال
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyModal;
